import { CheckBoxes, Form, Input, Label, Thanks } from '@/FormComponents';
import { PrimaryBtn } from '@/buttons';
import email from '@a/icons/email.svg';
import person from '@a/icons/person.svg';
import phone from '@a/icons/phone.svg';
import dots from '@a/images/homepage/dots.png';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { h2 } from './styles/Typography';
import { px, py } from './styles/classes';

const Section = styled.section`
    ${py};
    ${px};
    background-color: ${({ theme }) => theme.colors.light};

    > h2 {
        ${h2};
        font-weight: 600;
        letter-spacing: normal;
        text-align: center;
        margin: 0;
    }

    > p {
        text-align: center;
        margin: 12px 0 36px;
        color: rgba(7, 10, 21, 0.6);
    }

    @media (min-width: 1024px) {
        position: relative;

        ::before {
            content: url(${dots});
            position: absolute;
            top: 27px;
            right: 9.58%;
            z-index: -1;
        }

        ::after {
            content: url(${dots});
            position: absolute;
            top: 274px;
            left: 9.58%;
            z-index: -1;
        }
    }
`;

export const ContactForm = () => {
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData);
        data['interests'] = formData.getAll('interests').join(', ');

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
        setLoading(false);
        e.target.reset();
    };
    return (
        <Section>
            <h2>FEEL YOUR BEST AGAIN!</h2>
            <p>Also find other ways to feel younger, better and the best version of yourself</p>
            <Form onSubmit={handleSubmit} submit={submit} id="contact">
                <Label
                    htmlFor="name"
                    icon
                    css={css`
                        ::before {
                            background-image: url(${person});
                        }
                    `}
                >
                    Name *
                </Label>
                <Input type="text" id="name" name="name" placeholder="Full Name " required icon />

                <Label
                    htmlFor="phone"
                    icon
                    css={css`
                        ::before {
                            background-image: url(${phone});
                        }
                    `}
                >
                    Phone Number *
                </Label>

                <PatternFormat
                    type="tel"
                    format="+1 (###) ###-####"
                    customInput={Input}
                    placeholder="123 456-7890"
                    id="phone"
                    name="phone"
                    required
                    icon
                />

                <Label
                    htmlFor="email"
                    icon
                    css={css`
                        ::before {
                            background-image: url(${email});
                        }
                    `}
                >
                    Email address
                </Label>
                <Input type="email" id="email" name="email" placeholder="example@gmail.com" icon />

                <CheckBoxes>
                    <Label as="p">Select All that you are interested in:</Label>
                    <div>
                        <input
                            type="checkbox"
                            id="interest1"
                            name="interests"
                            value="Testosterone Therapy"
                        />
                        <label htmlFor="interest1">Testosterone Therapy</label>
                    </div>
                    <div>
                        <input type="checkbox" id="interest2" name="interests" value="Peptides" />
                        <label htmlFor="interest2">Peptides</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="interest3"
                            name="interests"
                            value="Semaglutide"
                        />
                        <label htmlFor="interest3">Semaglutide</label>
                    </div>
                </CheckBoxes>

                <Label htmlFor="message">Message</Label>

                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={7}
                    placeholder="Your Message for Us"
                />
                <PrimaryBtn disabled={loading}>
                    {loading ? 'Sending...' : 'Get free consultation today'}
                </PrimaryBtn>
                <Thanks submit={submit} setSubmit={setSubmit} />
            </Form>
        </Section>
    );
};
